<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-row :class="option === 1 ? 'justify-start align-start' : 'justify-center align-center'">
              <v-col
                cols="12"
                md="6"
                class="text-center"
              >
                <v-avatar
                  rounded
                  size="150"
                  class="mx-auto"
                  width="170"
                  height="170"
                  color="primary"
                >
                  <v-img
                    v-if="imageData || dataComputed.logo"
                    :src="imageData ? urlImage : dataComputed.logo"
                  ></v-img>
                  <span
                    v-else
                    class="font-weight-medium"
                  >{{ t('facilities.logo') }}</span>
                </v-avatar>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-row>
                  <!-- upload photo -->
                  <v-col cols="12">
                    <v-file-input
                      v-model="imageData"
                      accept=".jpg, .jpeg"
                      :placeholder="t('facilities.select_avatar')"
                      :label="t('facilities.logo')"
                      :prepend-icon="icons.mdiCamera"
                      outlined
                      dense
                      hide-details
                      :disabled="option===2"
                      @change="onFileSelected($event)"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="dataComputed.name"
                      :label="t('sports.name')"
                      :placeholder="t('sports.name')"
                      dense
                      outlined
                      :readonly="option===2"
                      :rules="[required]"
                    />
                  </v-col>
                </v-row>

                <!-- <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="dataComputed.facilities_ids"
                      :label="t('facilities.facility')"
                      :placeholder="t('facilities.facility')"
                      :items="computedFacilities"
                      item-text="name"
                      item-value="id"
                      :menu-props="{ maxHeight: '400' }"
                      multiple
                      persistent-hint
                      dense
                      outlined
                      :append-icon="option===2 ? null : icons.mdiMenuDown"
                      :readonly="option===2"
                      :rules="[required]"
                    >
                      <template #selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          label
                          dense
                          :input-value="data.selected"
                          @click="data.select"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row> -->
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="dataComputed.visibility"
              dense
              outlined
              :label="t('players_group.visibility')"
              :items="visibilityOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="dataComputed.status"
              dense
              outlined
              :label="t('status.status')"
              :items="statusOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-textarea
              v-model="dataComputed.description"
              :label="t('sports.description')"
              dense
              outlined
              rows="3"
              :readonly="option===2"
            ></v-textarea>
          </v-col>

          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :no-save="true"
            :info-required="true"
            :no-cancel="option === 3"
            :no-arrow="option === 2"
            @onCancel="onCancelButton"
            @onNextTab="changeTab"
            @onSave="onSaveAction"
          >
          </options-buttom-tab>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mdiAlertOutline, mdiCloudUploadOutline, mdiMenuDown } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import { required, emailValidator, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { info } from '@core/utils/toasted'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    facilities: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()

    const form = ref(null)
    const dataComputed = computed(() => props.dataParams)
    const computedFacilities = computed(() => props.facilities)

    const urlImage = ref(null)
    const imageData = ref(null)

    const statusOptions = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
    ])

    const visibilityOptions = computed(() => [
      { text: t('players_group.private'), value: 'P' },
      { text: t('players_group.public'), value: 'B' },
    ])

    const cleanImage = () => {
      imageData.value = null
      urlImage.value = null
    }

    const onFileSelected = e => {
      try {
        cleanImage()
        if (e && e.size >= 262144) info('Max size of 256K!')
        const type = e ? e.type.split('/').pop() : null
        if (e && (type === 'jpg' || type === 'png' || type === 'jpeg') && e.size < 262144) {
          imageData.value = e
          if (e) {
            urlImage.value = URL.createObjectURL(imageData.value)
          }
        } else {
          cleanImage()
        }
      } catch (err) {
        console.error('error', err)
      }
    }

    const onUpdateAvatar = async () => {
      if (imageData.value) {
        emit('updateAvatar', imageData.value)
      } else {
        info('Seleccione una imagen!')
      }
    }

    const onSaveAction = () => {
      if (form.value.validate()) {
        emit('save', dataComputed.value)
      }
    }

    const onCancelButton = () => {
      cleanImage()
      form.value.reset()
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        if (imageData.value) dataComputed.value.imageData = imageData.value
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    return {
      // data
      form,
      urlImage,
      imageData,

      // computed
      dataComputed,
      visibilityOptions,
      statusOptions,
      computedFacilities,

      // methods
      onCancelButton,
      changeTab,
      onSaveAction,
      onUpdateAvatar,
      onFileSelected,
      cleanImage,

      // validations
      required,
      emailValidator,
      integerValidator,

      // i18n
      t,

      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiMenuDown,
      },
    }
  },
}
</script>
