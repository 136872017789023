<template>
  <v-card
    id="sport-form"
    min-height="350"
  >
    <!-- actions -->
    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="secondary"
            dark
            absolute
            small
            top
            right
            fab
            class="mb-4 me-3"
            v-on="on"
            @click="goBack"
          >
            <v-icon>
              {{ icons.mdiTableArrowLeft }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.back_table") }}</span>
      </v-tooltip>
    </v-fab-transition>

    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
      color="secondary"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
        color="secondary"
        :disabled="tab.disabled"
      >
        <v-icon
          v-if="!tab.hide"
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item :key="0">
        <players-group-tab-general
          :data-params="playersGroupData"
          :tab="tab"
          :option="option"
          :facilities="facilitiesOptions"
          @changeTab="changeTab"
          @save="save"
        />
      </v-tab-item>

      <v-tab-item :key="1">
        <players-group-tab-info
          :data-params="playersGroupData"
          :tab="tab"
          :option="option"
          :facilities="facilitiesOptions"
          @save="save"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiTableArrowLeft,
  mdiAccountGroupOutline,
} from '@mdi/js'
import { ref, watch, onMounted } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { error, success } from '@core/utils/toasted'
import { createPlayersGroup, editPlayersGroup, editPlayersGroupLogo, getPlayersGroup } from '@api'
import useSelectOptions from '@/@core/utils/useSelectOptions'

// demos
import PlayersGroupTabGeneral from './PlayersGroupTabGeneral.vue'
import PlayersGroupTabInfo from './PlayersGroupTabInfo.vue'

export default {
  components: {
    PlayersGroupTabGeneral,
    PlayersGroupTabInfo,
  },
  setup() {
    const { t } = useUtils()
    const { route, router } = useRouter()
    const { configOrganization, configFacility, facilitiesOptions, fetchFacilitiesByOrganization } = useSelectOptions()

    const tab = ref(0)
    const option = ref(1)
    const pageData = ref()
    const filterData = ref()
    const backTo = ref()
    const users = ref([])
    const playersGroupData = ref({})
    const imageLogo = ref(null)
    const dataId = ref(null)
    const postData = ref({
      avatar: null,
      name: null,
      facilities_ids: [configFacility.value],
      status: null,
      visibility: null,
      description: null,
      organizers: [],
    })
    const putData = ref({
      avatar: null,
      name: null,
      facilities_ids: [configFacility.value],
      status: null,
      visibility: null,
      description: null,
      organizers: [],
    })

    // tabs
    const tabs = ref([
      {
        title: 'General',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
      },
      {
        title: 'Players',
        icon: mdiAccountGroupOutline,
        disabled: true,
        hide: false,
      },
    ])

    const changeTab = (tabNumber, validForm, data) => {
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
            imageLogo.value = data.imageData
          }
          break
        case 2:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[2].disabled = false
          }
          break
        default:
          break
      }

      tab.value = tabNumber
    }

    const updateAvatar = async image => {
      const formData = new FormData()
      if (image && dataId.value) {
        formData.append('file', image)
        const responseLogo = await editPlayersGroupLogo(formData, dataId.value)

        if (responseLogo.ok) {
          success(responseLogo.message)
        }
      }
    }

    const save = async data => {
      let resp = null
      if (option.value === 1) {
        postData.value = Object.assign(postData.value, {
          name: data.name,
          facilities_ids: data.facilities_ids,
          organizers: data.organizers,
          status: data.status,
          visibility: data.visibility,
          description: data.description,
        })
        resp = await createPlayersGroup(postData.value)
        if (resp.ok) {
          dataId.value = resp.data.id
          if (imageLogo.value) {
            await updateAvatar(imageLogo.value)
          }
        }
      }

      if (option.value === 3) {
        putData.value = Object.assign(putData.value, {
          name: data.name,
          facilities_ids: data.facilities_ids,
          organizers: data.organizers,
          status: data.status,
          visibility: data.visibility,
          description: data.description,
        })
        resp = await editPlayersGroup(putData.value, data.id)
        if (imageLogo.value) {
          await updateAvatar(imageLogo.value)
        }
      }

      if (!option.value) {
        router.go(-1)
      }

      if (resp.ok) {
        router.go(-1)
        success(resp.message)
      } else {
        error(resp.message.text)
      }
    }

    const cancelAction = () => {
      tab.value = 0
      tabs.value[1].disabled = true
      tabs.value[2].disabled = true
      playersGroupData.value = {}
    }

    const goBack = () => {
      if (backTo.value) {
        router.push({
          name: backTo.value,
          params: {
            pageParams: pageData.value,
            filterParams: filterData.value,
          },
        })
      } else router.go(-1)
    }

    watch([configOrganization, configFacility], () => {
      goBack()
    })

    onMounted(async () => {
      option.value = route.value.params.option || 2
      dataId.value = route.value.params.id
      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams
      backTo.value = route.value.params.backTo
      if (route.value.params.users) users.value = route.value.params.users
      if (route.value.params.facilityId) playersGroupData.value.facilities_ids = [route.value.params.facilityId]
      else playersGroupData.value.facilities_ids = [configFacility.value]

      if (dataId.value) {
        const resp = await getPlayersGroup(dataId.value)
        if (resp.ok) playersGroupData.value = resp.data
        else goBack()
      } else option.value = 1

      if (option.value !== 1) {
        tabs.value.forEach(e => {
          e.disabled = false
        })
      }

      await fetchFacilitiesByOrganization()
    })

    return {
      tab,
      tabs,
      option,
      dataId,
      playersGroupData,
      postData,
      putData,
      facilitiesOptions,
      pageData,
      filterData,
      backTo,
      users,
      imageLogo,

      changeTab,
      save,
      cancelAction,
      goBack,

      // i18n
      t,

      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiTableArrowLeft,
      },
    }
  },
}
</script>
