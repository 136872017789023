<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row justify="center">
          <v-col
            md="7"
            cols="12"
          >
            <v-app-bar
              flat
              color="rgba(0, 0, 0, 0)"
              dense
              class="px-4"
            >
              <v-toolbar-title class="text-body-2 white--text pl-0">
                {{ t('notify_players.organizers') }}
              </v-toolbar-title>
            </v-app-bar>

            <v-card-text class="pa-0 mt-5 mb-7 justify-center d-flex flex-column">
              <v-row class="justify-center align-center">
                <v-col
                  v-for="(phoneInput, index) in phoneInputs"
                  :key="`phone-input-${index}`"
                  cols="12"
                  class="py-0"
                >
                  <div class="d-flex justify-space-between align-items-start">
                    <v-autocomplete
                      v-model="phoneInput.code"
                      dense
                      outlined
                      :items="phoneCodeOptions"
                      item-text="text"
                      item-value="value"
                      style="
                        width: 35%;
                      "
                      :append-icon="option!==2 ? icons.mdiMenuDown : null"
                      :readonly="option===2"
                      :hide-details="index > 0"
                      :rules="[required]"
                      @change="() => phoneInput.value = null"
                    >
                      <template v-slot:item="{ item }">
                        <img
                          :src="item.image"
                          :alt="item.value"
                          width="20"
                          height="auto"
                          class="mr-2"
                        >
                        {{ item.text }}
                      </template>
                    </v-autocomplete>

                    <v-text-field
                      v-model="phoneInput.value"
                      :label="t('notify_players.phone')"
                      dense
                      outlined
                      class="pl-2"
                      :class="{ 'mb-2': index > 0 }"
                      :disabled="!phoneInput.code"
                      :readonly="option===2"
                      :hide-details="index > 0"
                      :rules="(index === 0) ? [required, validatePhones(phoneInput.value, phoneInput)] : [validatePhones(phoneInput.value, phoneInput)]"
                      :append-icon="option !== 2 && index > 0 ? icons.mdiClose : null"
                      @click:append="deletePhoneNumber(index)"
                      @keydown.enter.prevent="addPhoneNumber"
                    ></v-text-field>

                    <v-text-field
                      v-model="phoneInput.fullname"
                      :label="t('users.name')"
                      dense
                      outlined
                      class="pl-2"
                      :class="{ 'mb-2': index > 0 }"
                      :disabled="!phoneInput.code"
                      :readonly="option===2 || phoneInput.isUser === 'Y'"
                      :hide-details="index > 0"
                      :rules="(index === 0 || phoneInput.value) ? [required] : []"
                    >
                      <template v-slot:prepend-inner>
                        <v-tooltip
                          bottom
                        >
                          <template #activator="{ on: tooltip, attrs }">
                            <v-icon
                              size="20"
                              :color="phoneInput.isUser === 'Y' ? 'success' : 'info'"
                              v-bind="attrs"
                              v-on="{ ...tooltip }"
                            >
                              {{ phoneInput.isUser === 'Y' ? icons.mdiAccountCheck : icons.mdiAccount }}
                            </v-icon>
                          </template>
                          <span>{{ phoneInput.isUser === 'Y' ? 'Is User' : 'Guess' }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </div>
                </v-col>

                <v-col
                  v-if="option !== 2"
                  cols="12"
                  class="py-0"
                >
                  <p
                    id="addBtnForm"
                    class="text-body-1 ma-0 btn-add cursor-pointer"
                  >
                    + {{ t('notify_players.add_phone') }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>

          <v-col
            v-if="option !== 1"
            md="5"
            cols="12"
          >
            <v-app-bar
              flat
              color="rgba(0, 0, 0, 0)"
              dense
              class="px-4"
            >
              <v-toolbar-title class="text-body-2 white--text pl-0">
                {{ t('notify_players.members') }}
              </v-toolbar-title>
            </v-app-bar>

            <v-card-text class="pa-0 mt-5 mb-7">
              <v-row
                justify="center"
                class="members-scroll"
              >
                <v-col
                  v-for="(member, index) in dataComputed.members"
                  :key="`phone-input-${index}`"
                  cols="12"
                  md="10"
                  class="py-0"
                >
                  <div class="d-flex align-center my-2">
                    <v-avatar
                      color="primary"
                      :class="member.avatar ? '' : 'v-avatar-light-bg primary--text'"
                      size="32"
                    >
                      <v-img
                        v-if="member.avatar"
                        :src="member.avatar"
                      ></v-img>
                      <span
                        v-else
                        class="font-weight-medium"
                      >{{ avatarText(member.fullname) }}</span>
                    </v-avatar>

                    <div class="d-flex flex-column ms-3">
                      <p
                        class="text--primary font-weight-semibold text-truncate text-decoration-none ma-0"
                      >
                        {{ member.fullname }}
                      </p>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>

          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :no-save="false"
            :info-required="true"
            :no-cancel="option === 3"
            :no-arrow="true"
            @onCancel="onCancelButton"
            @onSave="onSaveAction"
          >
          </options-buttom-tab>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import { mdiAccountCheck, mdiAccount, mdiClose, mdiMenuDown } from '@mdi/js'
import { ref, computed, onMounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import useCountry from '@core/utils/useCountry'
import usePhone from '@core/utils/usePhone'
import { parsePhoneNumber } from 'libphonenumber-js'
import { avatarText } from '@core/utils/filter'
import { error } from '@core/utils/toasted'
import { getPlayersGroupsSearch } from '@api'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    facilities: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { getCountryCode } = useCountry()
    const { phoneCodeOptions, initPhoneConfig } = usePhone()

    const form = ref(null)
    const dataComputed = computed(() => props.dataParams)

    const initCode = ref('')
    const phoneInputs = ref([
      { value: null, code: null, prefix: null, phone: null, fullname: null, username: null, isUser: null },
    ])

    const addPhoneNumber = () => {
      if (phoneInputs.value.length < 30) {
        phoneInputs.value.push({ value: null, code: initCode.value, prefix: null, phone: null, fullname: null, username: null, isUser: null })
      }
    }

    const deletePhoneNumber = index => phoneInputs.value.splice(index, 1)

    const fetchUserByPhoneNumber = async phoneInput => {
      const respSearch = await getPlayersGroupsSearch(phoneInput.phone)
      const search = respSearch.data
      if (search) {
        phoneInput.fullname = search.fullname
        phoneInput.isUser = search.is_user
        phoneInput.username = search.username
        phoneInput.prefix = search.phone_prefix
      } else phoneInput.isUser = 'N'
    }

    const validatePhones = (e, phoneInput) => {
      if (phoneInput.code && e && e.length > 3) {
        let phoneNumberJS = parsePhoneNumber(e, phoneInput.code)

        if (phoneNumberJS) {
          if (phoneNumberJS.isValid()) {
            phoneInput.phone = phoneNumberJS.number
            phoneInput.prefix = `+${phoneNumberJS.countryCallingCode}`
            if (!phoneInput.isUser) fetchUserByPhoneNumber(phoneInput)
            phoneNumberJS = undefined

            return true
          }
        }
      }

      phoneInput.fullname = null
      phoneInput.isUser = null
      phoneInput.username = null
      phoneInput.prefix = null

      if (e === '' || e === null) return true

      return 'Invalid number'
    }

    const onSaveAction = () => {
      if (form.value.validate()) {
        dataComputed.value.organizers = phoneInputs.value.filter(e => e.phone).map(e => {
          return {
            username: e.username,
            fullname: e.fullname,
            phone_number: e.phone,
            phone_code: e.code,
            phone_prefix: e.prefix,
            is_user: e.isUser,
          }
        })

        const arrPhones = dataComputed.value.organizers.map(e => e.phone_number)
        const newArrPhones = new Set(arrPhones)
        if (newArrPhones.size === arrPhones.length) emit('save', dataComputed.value)
        else error('duplicate phone numbers')
      }
    }

    const onCancelButton = () => {
      form.value.reset()
    }

    onMounted(async () => {
      const countryCode = getCountryCode()
      if (countryCode) {
        const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
        if (codeFinded) initCode.value = countryCode
        else initCode.value = 'US'
      }

      if (props.option === 1) phoneInputs.value[0].code = initCode.value

      if (props.option !== 1) {
        phoneInputs.value = props.dataParams.organizers.map(e => {
          return {
            value: e.phone_number.replace(e.phone_prefix, ''),
            code: e.phone_code,
            prefix: e.phone_prefix,
            phone: e.phone_number,
            fullname: e.fullname,
            username: e.username,
            isUser: e.is_user,
          }
        })
      }

      if (props.option !== 2) {
        setTimeout(() => {
          const btn = document.getElementById('addBtnForm')
          btn.addEventListener('click', addPhoneNumber)
        }, 200)
      }
    })

    return {
      // data
      form,
      phoneInputs,

      // computed
      dataComputed,
      phoneCodeOptions,

      // methods
      onCancelButton,
      onSaveAction,
      addPhoneNumber,
      deletePhoneNumber,
      fetchUserByPhoneNumber,
      avatarText,

      // validations
      required,
      validatePhones,

      // i18n
      t,

      icons: {
        mdiMenuDown,
        mdiAccountCheck,
        mdiAccount,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.members-scroll {
  height: auto;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
